import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";
import axios from 'axios'

import InstanceList from './instances/InstanceList'
import Instance from './instances/Instance'
import Login from './Login';
import PrivateRoute from './PrivateRoute';

import './App.css';
import 'typeface-roboto'

class App extends Component {

  componentDidMount() {
    console.log('axios config');
    axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      console.log('error')
      if(error && error.response && error.response.data === 'TokenExpiredError') {
        console.log('token expirado');
        localStorage.removeItem('token');
        window.location.href = '/login'
      }
      return Promise.reject(error);
    });
  }

  render() {
    return (
      <Router>
        <div className="App">
          <Route path="/login" component={Login}/>
          <PrivateRoute path="/" exact component={InstanceList} />
          <PrivateRoute path="/instance/:id" component={Instance}/>
        </div>
      </Router>
    );
  }
}

export default App;
