import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {withStyles} from '@material-ui/core/styles'
import { Paper, TableRow, TableHead, TableCell, TableBody, Table, Button, Grid } from '@material-ui/core'
import { Typography } from '@material-ui/core'

const styles = theme => ({
    root: {
        width: '80%',
        margin: 'auto',
        marginTop: '50px'
    },
    rowStyle: {
        cursor: 'pointer'
    }
})

function InstanceList(props) {

    const [instances, setInstances] = useState([]);
    const {classes} = props;
    
    useEffect(() => {
        const fetchInstances = async () => {
            const result = await axios.get('/api/instances')
            console.log(result.data);
            setInstances(result.data)
        }        
        fetchInstances();
        // solo nos interesa cargar las instancias una vez
    }, []);

    const handleRowClick = (id) => () => {
        props.history.push(`/instance/${id}`)
    }

    function logout() {
        localStorage.removeItem('token');
        props.history.push('/login')
    }

    return (
        <div className={classes.root}>
            <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h3" gutterBottom>
                        Instancias
                    </Typography>                    
                </Grid>
                <Grid item>
                    <Button color="primary"
                        onClick={logout}>
                        Cerrar sesión
                    </Button>
                </Grid>
            </Grid>
            <Paper>            
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nombre</TableCell>
                            <TableCell>URL</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            instances.map(i => (
                                <TableRow 
                                    className={classes.rowStyle}
                                    key={i.id}
                                    onClick={handleRowClick(i.id)}>
                                    <TableCell>{i.name}</TableCell>
                                    <TableCell>
                                        <a href={i.url} target="_blank">{i.url}</a>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </Paper>
        </div>
    )

}

export default withStyles(styles)(InstanceList)