
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { Paper, Grid } from '@material-ui/core'
import { BarChart, Bar, CartesianGrid, XAxis, YAxis } from 'recharts'

const styles = theme => ({
    root: {
        padding:'20px',
        margin:'20px'
    }
})

function Chart({classes, data, xKey, yKey, title}) {

    const last = data.length > 0 ? data[data.length - 1] : {}

    return (
        <Paper className={classes.root}>
            <Typography variant="h6" gutterBottom>
                {title}
            </Typography>
            Empleados
            <br/>
            <br/>
            <Grid container spacing={16}>
                <Grid item>
                    <BarChart width={400} height={200} data={data}>
                        <Bar dataKey={yKey} fill="#8884d8"/>
                        <CartesianGrid stroke="#ccc" />
                        <XAxis dataKey={xKey} />
                        <YAxis />
                    </BarChart>
                </Grid>
                <Grid item>
                    <p>Último resultado:</p>
                    <p>Día: {last[xKey]}</p>
                    <p>Empleados: {last[yKey]}</p>
                </Grid>
            </Grid>            
            <div style={{width:'400px', textAlign:'center'}}>Día</div>
        </Paper>
    )
}

export default withStyles(styles)(Chart)