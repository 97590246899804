import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { Paper, TextField } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import moment from 'moment'
import Chart from '../shared/Chart'

const months = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
]

const styles = theme => ({
    root: {
        width: '80%',
        margin: 'auto',
        marginTop: '50px'
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: '40px'
    },
    header: {
        marginBottom: '20px'
    }
})

const dataTypeMap = {
    'completados':'Activos',
    'enProceso':'En proceso',
    'bajas':'Bajas'
}

function CurrentMonth({date}) {
    const current = moment.utc(date);
    const month = months[current.month()]
    return (
        <span>{month} - {current.year()}</span>
    )
}
function getCurrentMonthRange() {
    const today = moment.utc();
    return {
        end: today.endOf('month').format('YYYY-MM-DD'),
        start: today.startOf('month').format('YYYY-MM-DD')       
    }
}

function Instance(props) {

    const [instance, setInstance] = useState({})
    const [data, setData] = useState([])
    const [query, setQuery] = useState({
        dataType: 'completados',
        ...getCurrentMonthRange()
    })
    const {classes} = props;    
    
    useEffect(() => {        
        const fetchInstance = async () => {
            const result = await axios.get(`/api/instances/${props.match.params.id}`);
            setInstance(result.data)
        }
        // solo nos interesa traerlo cuando carga la pagina
        fetchInstance();        
    }, [])

    useEffect(() => {
        const fetchData = async () => {            
            const result = await axios.get(`/api/employees?instanceId=${props.match.params.id}&from=${query.start}&to=${query.end}`)
            setData(result.data.map(r => ({
                ...r,
                day: moment(r.date).date()
            })))
        }
        fetchData()
    }, [query])

    function changeMonth(change) {
        const changed = moment.utc(query.start).add(change, 'month');
        const start = changed.startOf('month').format('YYYY-MM-DD')
        const end = changed.endOf('month').format('YYYY-MM-DD')
        console.log({
            start, end
        })
        setQuery({
            ...query,
            start, end
        })
    }

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography variant="h3" gutterBottom>
                    {instance.name}
                </Typography>
                <p>Fecha creación: {moment(instance.createdAt).format('YYYY-MM-DD')}</p>
                <p><a href={instance.url} target="_blank">{instance.url}</a></p>
            </div>
            <Typography variant="h5" gutterBottom>
                Empleados
            </Typography>
            <div>
                <IconButton onClick={e => changeMonth(-1)}>
                    <ArrowBackIcon/>
                </IconButton>
                <CurrentMonth date={query.start}/>
                <IconButton onClick={e => changeMonth(1)}>
                    <ArrowForwardIcon/>
                </IconButton>
            </div>         
            <Chart title={'Activos'}  data={data} xKey="day" yKey="completados"/>
            <Chart title={'En proceso'}  data={data} xKey="day" yKey="enProceso"/>
            <Chart title={'Bajas'}  data={data} xKey="day" yKey="bajas"/>
        </div>
    )
}

export default withStyles(styles)(Instance)