import React from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

function isSignedUp() {
    const token = localStorage.getItem('token');
    if(token) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
    return token;
}

function PrivateRoute(props) {
    const {component: Component, ...rest} = props
    return (
        <Route {...rest} render={ props => isSignedUp() ? 
            <Component {...props}/> :
            <Redirect to={{
                pathname: "/login"
            }} />
        }
        />        
    )
}

export default PrivateRoute;